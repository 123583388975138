import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyauthGuard } from './service/myauth.guard';



const routes: Routes = [

  {  
    path: 'exchange',
    canActivate: [MyauthGuard],
    loadChildren: ()=> import('./exchangeuser/exchangeuser.module').then(m=>m.ExchangeuserModule)
  },
  {  
    path: 'assistance',
    canActivate: [MyauthGuard],
    loadChildren: ()=> import('./assistance/assistance.module').then(m=>m.AssistanceModule)
  },
  {  
    path: 'users',loadChildren: ()=> import('./usersgest/usersgest.module').then(m=>m.UsersgestModule)
  },
  {  
    path: 'home', loadChildren: ()=> import('./home/home.module').then(m=>m.HomeModule)
  },
  {
    path: 'admin',
    canActivate: [MyauthGuard],
    loadChildren: () => import('./sidebarapp/sidebarapp.module').then( m => m.SidebarappModule)
  },
  {
    path: 'admopen',
    canActivate: [MyauthGuard],
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule)
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {  
    path: '**',loadChildren: ()=> import('./not-found/not-found.module').then(m=>m.NotFoundModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
