import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountService } from './account.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AccountGuard implements CanActivate {

    constructor(private accountService: AccountService,  private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        //check if the user is already logged
        // if (route.data.action == 'logged') {

        //     if (this.accountService.getAuth()){
        //         if (this.accountService.welcomedUser) {
        //             return true;
        //         }
        //         else {
        //             // this.pageService.create_notification("info", "Bienvenue "+this.accountService.getAccount().prenom);
                   
        //         }
        //     }
        // }

        return true;

    }


}