import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class AccountService {

    private isAuth: boolean = true;
    private profile = {
        nom: "Back",
        prenom: "Gracieux",
        IDuser: 1,
        IDuserType:2,
        userType: 'Directeur General',
        photo: "./assets/img/avatarH.png",
        etablissement: [17],
        currentEtablissement: 17,
    };

    //say if we already synchronized user account information to the server
    public checkedAccountInfoFromServer = false;

    //say if we alreday printed a welcom message to a user
    public welcomedUser = false;

    profileSubject = new Subject<{}>();

    constructor() { }

    setAuth(state: boolean) {
        this.isAuth = state;
    }

    getAuth(): boolean {
        return this.isAuth;
    }

    getProfile() {
        this.profileSubject.next(this.profile)
    }

    setProfile(account) {
        this.profile = account;
        this.getProfile();
    }

    getAccount() {
        return this.profile;
    }

}