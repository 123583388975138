import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { isArray, isObject } from "util";
import * as frLocale from 'date-fns/locale/fr';

declare var $: any;

// hfrom this service we extends http methods
// notification and translation
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: 'root'
})

export class BaseService extends ConfigService {

    private data;
    dataSubject = new Subject<any>();
    private serverUrl: string;
    currentItem;

    constructor(serverUrl: string) {
        super();
        this.serverUrl = serverUrl;

    }

    getServerUrl(): string {
        return this.serverUrl;
    }

    emitData() {
        this.dataSubject.next(this.data);
    }

    setData(data) {
        this.data = data;
        this.emitData();
    }


    getData() {
        return this.data;
    }

    add(item) {
        //check if data structure has pagination
        if(this.data.data.current_page){
            this.data.data.data.unshift(item);
        }
        else{
            this.data.data.unshift(item);
        }
        this.emitData();
    }

    // edit element in current source data
    // So param could be
    // an index to an array element
    // or a a children of an array element would has to be an abject
    // according to model below
    // {parentIndex:null, level:number, children:Array<string>} 
    // level start at 0;
    // and children start at 0 too
    edit_2(data, index: number = null, multi: { parentIndex: number | string, level: number, children: Array<string> } = null) {
        // in case we have a complex data organigramm with multi level children
        if (isObject(multi)) {
            // get fisrt base parent according to data reference
            let parent = this.data.data[multi.parentIndex];
            // 


        }
        else if (index) {
            this.data.data[index] = data;
            this.emitData();
        }
    }

    edit(data, index: number = null) {
        if(this.data.data.current_page){
            this.data.data.data[index] = data;
        }
        else{
            this.data.data[index] = data;
        }
        this.emitData();   
    }

    editByKey(target:string, index:number, value:any) {
        this.data[target][index] = value;
        this.emitData();   
    }
       

    del(index) {
        if(this.data.data.current_page){
            this.data.data.data.splice(index, 1);
        }
        else{
            this.data.data.splice(index, 1);
        }
        this.emitData();
    }

    init($url=null) {
        if(!$url){
            $url = this.serverUrl;
        }
        this.setLoading(true);
        this.get($url).subscribe(
            (res) => {
                this.data = res;
                this.emitData();
                this.setLoading(false);
            },
            (error) => {
                this.create_notification("error", error);
            }
        );
    }

    // get all the data without any pagination
    getAllData($url=null): Observable<any> {
        if(!$url){
            $url = this.serverUrl+'/all';
        }
        return this.get($url);
    }

    refresh() {
        this.emitData();
    }

    translateArrayItem(obj: Array<any>, key: string) {
        // a empty array to store all result
        let arr = [];
        // the current translated item
        let translatedItem: {};

        if (obj) {
            obj.forEach(item => {
                // Store each element
                translatedItem = item;
                // search the good translation for the given key
                this.getTranslation(item[key]).subscribe(
                    (translation: string) => {
                        // set translation
                        translatedItem[key] = translation;
                        arr.push(translatedItem);
                    },
                    (notFound) => {
                        arr.push(translatedItem);
                    }
                )

            });
        }

        return arr;
    }

    // pagination
    checkPaginationParam(page): string {
        // we check if the param is availble
        if (parseInt(page)) {
            return this.serverUrl + '/?page=' + page;
        }
        else // If param given isn't a number
        {
            this.notify("error", 'urlParamError');
            return this.serverUrl;
        }
    }

    // renome les références d'objet dans un tableau
    rename_reference($arr: Array<any>, $key): Array<any> {
        let result = [];

        //on vérifie si on a vraiment reçu un tableau
        if (isArray($arr)) {
            // on vérifie que le tableau repond à la bonne structure
            // enoncée dans key
            if ($arr[0][$key[0]['currentKey']]) {
                $arr.forEach(element => {
                    // renome chaque référence par sa nouvelle clé equivalente
                    // Ensuite efface l'ancienne référence
                    $key.forEach((item: any) => {
                        Object.defineProperty(element, item.newKey,
                            Object.getOwnPropertyDescriptor(element, item.currentKey));
                        delete element[item.currentKey];

                        // Enregistre le nouvel objet fraichement modifié
                        result.push(element);
                    });
                });
            }
            else {
                result = $arr;
            }

        }
        else {
            result = $arr;
        }

        return result;

    }

    // supprime des références d'objet dans un tableau
    remove_reference($arr: Array<any>, $key: Array<any>): Array<any> {
        let result = [];
        if (isArray($arr) && $arr[0]) {
            // on vérifie que le tableau repond à la bonne structure
            // comme enoncée dans $key
            if ($key[0] in $arr[0]) {
                $arr.forEach(element => {
                    // efface chaque element 
                    // en fonction des references précisées dans $key
                    $key.forEach((item: any) => {
                        delete element[item];
                        // Enregistre le nouvel objet fraichement modifié
                        result.push(element);
                    });
                });
            }
            else {
                result = $arr;
            }
        }
        else {
            result = $arr;
        }

        return result;
    }


    // get array values for an given key without reference
    get_array_key_values($arr: Array<any>, $key: string): Array<any> {
        let result = [];

        if (isArray($arr) && $arr[0]) {
            // on vérifie que le tableau repond à la bonne structure
            // enoncée dans key
            if ($arr[0][$key]) {
                $arr.forEach(element => {
                    // enregitre chaque valeur de la reference 
                    result.push(element[$key]);
                });
            }
            else {
                result = $arr;
            }
        }
        else {
            result = $arr;
        }

        return result;
    }

    arrayLastElement(lastElement: Array<any>): Object {
        let result = {};
        //get last element of an array
        if (lastElement[0]) {
            lastElement.forEach(item => {
                result[item] = this.data.data[item][this.data.data[item].length - 1];
            })
        }

        return result;
    }

    // check and format file
    formatFile(event, param : {size:number, fileType:[]}): File | null {
        let file = event.target.files[0];

        let fileToUpload: File = null;

        //  var pattern = /image-*/;
        var pattern = /image-jpg|jpeg|png/;

        if (file.type.match(param.fileType)) {

            if (file.size <= param.size) {
                fileToUpload = file;
            }
            else {
                this.notify("warning", 'fileSizeIncorrect');
            }
        }
        else {
            this.notify("warning", 'fileFormatIncorrect');
        }

        return fileToUpload;
    }

    // check an element
    check_element(element: any, valueToReturnIfFalse: any): any {
        if (element) {
            return element;
        }
        else {
            return valueToReturnIfFalse;
        }
    }


    closeModal(element: string) {
        $(element).modal('toggle');
    }

    /**************************
    * DATE AND CALENDAR METHODS
    **************************/
    yearFromNowOn(start) {
        let today = new Date();
        let years = [];
        while (start <= today.getFullYear()) {
            years.push(start);
            start++;
        }
        return years;
    }

    lastCurrentMonthDay() {
        let today = new Date();
        const dd = today.getDate();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    }

    mysql_date_format(myDate) {
        let d = new Date(myDate);
        return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    }

    launchMaterialStyleForm() {
        $(".input").focus(function () {
            $(this).parent().addClass("focus");
            console.log('focus');
        })
    }


    toggleBlockSlide(toggle){
        if(toggle){
            $('.block-slide').show(500);
        }
        else{
            $('.block-slide').hide(500);
        } 
    }

    hideBlockSlide(){
        $('.block-slide').hide();
    }


    setDateOptions(mimYear:number=1930, minDate:string='1930-1-1'):Object {
        let placeholder = '';
        this.getTranslation('selectDate').subscribe(translation => placeholder = translation);
        let barTitleIfEmpty = '';
        this.getTranslation('moreOptionHere').subscribe(translation => barTitleIfEmpty = translation);
        return {
          locale: frLocale,
          minYear: 1930,
          maxYear: new Date().getFullYear() + 1,
          displayFormat: 'D[,] MMM YYYY',
          barTitleFormat: 'MMMM YYYY',
          minDate: new Date('1930-1-1'),
          maxDate: new Date(Date.now()),
          barTitleIfEmpty: barTitleIfEmpty,
          placeholder: placeholder
        };
      }
      
    setDateOptionsInverse(mimYear:number=1930, minDate:string='1930-1-1'):Object {
        let placeholder = '';
        this.getTranslation('selectDate').subscribe(translation => placeholder = translation);
        let barTitleIfEmpty = '';
        this.getTranslation('moreOptionHere').subscribe(translation => barTitleIfEmpty = translation);
        return {
          locale: frLocale,
          minYear: new Date().getFullYear(),
          maxYear: new Date().getFullYear() + 1,
          displayFormat: 'D[,] MMM YYYY',
          barTitleFormat: 'MMMM YYYY',
          minDate: new Date(Date.now()),
        
          barTitleIfEmpty: barTitleIfEmpty,
          placeholder: placeholder
        };
      }
}