import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})

export class EtablissementService extends BaseService {
    constructor() {
        super('etablissement');

    }

    // init multi select setting option for adding an editing forms
    initMultiSelectSettingOption(idField: string, textField: string): Object {

        let selectAllText = '';
        let unSelectAllText = '';
        let searchPlaceholderText = '';
        let noDataAvailablePlaceholderText = '';

        // TRANSLATE LABEL
        this.getTranslation('selectAll').subscribe(
            (value) => {
                selectAllText = value;
            }
        );
        this.getTranslation('deselectAll').subscribe(
            (value) => {
                unSelectAllText = value;
            }
        );
        this.getTranslation('SEARCH').subscribe(
            (value) => {
                searchPlaceholderText = value;
            }
        );

        this.getTranslation('noData').subscribe(
            (value) => {
                noDataAvailablePlaceholderText = value;
            }
        );

        return {
            singleSelection: false,
            idField: idField,
            textField: textField,
            selectAllText: selectAllText,
            unSelectAllText: unSelectAllText,
            searchPlaceholderText: searchPlaceholderText,
            noDataAvailablePlaceholderText: noDataAvailablePlaceholderText,
            itemsShowLimit: 1,
            allowSearchFilter: true,
            maxHeight: 800
        };
    }

}