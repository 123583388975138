import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  fullUrl = environment.apiUrl;
  $id=3;
  currentuser;
  reseturl=environment.api_url;


  constructor(private http: HttpClient,public translate:TranslateService, public router: Router) { }
  //Methode Http
  post(hote: string, gert: any) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote;
    //const obj = JSON.parse(JSON.stringify(gert));
    return this.http.post(url, gert,$request);
  }
  resetemail(hote: string) {
    // const url = this.reseturl + hote;
    const url = this.fullUrl + hote;
    return this.http.get(url);
  }

  postfile(hote: string, gert: any,id) {
    const url = this.fullUrl + hote+id;
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    //const obj = JSON.parse(JSON.stringify(gert));
    return this.http.post(url, gert,$request);
  }


  put(hote: string, gert: any, id) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote + id;
    //const obj = JSON.parse(JSON.stringify(gert));
    return this.http.put(url, gert,$request);
  }

  delete(hote: string, $id) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote + $id;
    return this.http.delete(url,$request);
  }
  get(hote: string) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote;
    return this.http.get(url,$request);
  }
  getById(hote: string, $id) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote + $id;
    return this.http.get(url,$request);
  }
    //search name
  search(hote:string,formData : string) {
    const url = this.fullUrl + hote + formData;
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
      //const obj = JSON.parse(JSON.stringify(formData));
      return this.http.get(url,$request);
  
  }
  getTranslation(key: string): Observable<any> {
    return this.translate.get(key);
  }
  console() {
    console.clear();
    this.getTranslation('KONSOLE').subscribe(
      (translation) => {
        console.log("%c" + translation, "color: red; font-size: x-large");
      }
    );

    this.getTranslation('KonsoleDescription').subscribe(
      (translation) => {
        console.log("%c" + translation, "color: black; font-size: 20px");
      }
    );
  }
  getAnnee(){
    return ['2022', '2023', '2024', '2025', '2026', '2027', '2028'];
  }
  getMois(){
    return [{'id':1,'value':'Janvier'},{'id':2,'value':'Fevrier'},{'id':3,'value':'Mars'},{'id':4,'value':'Avril'},{'id':5,'value':'Mai'},{'id':6,'value':'Juin'},
    {'id':7,'value':'Juillet'},{'id':8,'value':'Aout'},{'id':9,'value':'Septembre'},{'id':10,'value':'Octobre'},{'id':11,'value':'Novembre'},{'id':12,'value':'Decembre'}];
  }
  getOnlymois(){
    return ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Decembre'];
  }
  getcolordash(){
    return ['#2980B9', '#34495E', '#3498DB', '#273746', '#7FB3D5', '#717D7E', '#1A5276', '#AAB7B8', '#0f4c5c', '#2e4053', '#03045e','#5d6d7e'];
  }

  getcurrentuser(){
    var $user={'nom':localStorage.getItem('nom'),'prenom':localStorage.getItem('prenom'),
    'sexe':localStorage.getItem('sexe'),'photo':localStorage.getItem('photo'),'auth':localStorage.getItem('iduser'),
    'role':JSON.parse(localStorage.getItem('role')),'module':JSON.parse(localStorage.getItem('module')),
    'sous_module':JSON.parse(localStorage.getItem('sous_module')),'agence':JSON.parse(localStorage.getItem('agence')),
    'message':JSON.parse(localStorage.getItem('message')), 'employer_agence':JSON.parse(localStorage.getItem('employer_agence')),
    'courtier':JSON.parse(localStorage.getItem('courtier')),'type_compte':localStorage.getItem('type_compte'),
    'is_admin':localStorage.getItem('is_admin')};
    return $user;
    //return this.currentuser;
  }
  //Login
  setcurrentuser($data){
    this.currentuser=$data;
  }
  getToken() {
    return localStorage.getItem('access_token');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }
  doLogout() {
    this.post('/logout',{}).subscribe(
      (data)=>{
          localStorage.clear();
          this.router.navigate(['users/login']);
        console.log('logout');
      },
      (error)=>{
        localStorage.clear();
        this.router.navigate(['users/login']);
        console.log(error);
      }
    );
  }
 
  setlocalstorage($data){
    localStorage.setItem('access_token',$data.access_token);
    localStorage.setItem('prenom',$data.user.prenom);
    localStorage.setItem('nom',$data.user.nom);
    localStorage.setItem('sexe',$data.user.sexe);
    localStorage.setItem('photo',$data.user.photo);
    localStorage.setItem('role',JSON.stringify($data.role));
    localStorage.setItem('module',JSON.stringify($data.module));
    localStorage.setItem('sous_module',JSON.stringify($data.sous_module));
    localStorage.setItem('agence',JSON.stringify($data.agence));
    localStorage.setItem('employer_agence',JSON.stringify($data.employer_agence));
    localStorage.setItem('message',JSON.stringify($data.message));
    localStorage.setItem('iduser',$data.user.id_inscription);
    localStorage.setItem('type_compte',$data.user.type_compte);
    localStorage.setItem('is_admin',$data.user.is_admin);
    if($data.user.type_compte=='Compte Publicitaire'){
      localStorage.setItem('courtier',JSON.stringify("Oui"));
    }
    if($data.user.type_compte!='Compte Publicitaire'){
      localStorage.setItem('courtier',JSON.stringify("Non"));
    }
  }
 
  gettokenparams($token){
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${$token}`
    });

   const requestOptions = { headers: headers };
   return requestOptions;
  }

  getnextweek(){
    var  nextweek=[];
    var mycurrentdate=new Date();
    var currentmonth=mycurrentdate.getMonth() + 1;
    var currentyear=mycurrentdate.getFullYear();
    var currentday=mycurrentdate.getDate();
    var currentdate=currentyear+'-'+currentmonth+'-'+currentday;

    var $val = new Date(currentdate);
      // Starting Monday not Sunday
      $val.setDate(($val.getDate() - $val.getDay() +8));
    for (var i = 0; i < 7; i++) {
      var myweekdate=new Date($val);
      var myweekmonth=myweekdate.getMonth() + 1;
      var myweekyear=myweekdate.getFullYear();
      var myweekday=myweekdate.getDate();
      var myweek=myweekyear+'-'+myweekmonth+'-'+myweekday;
      nextweek.push(myweek); 
      $val.setDate($val.getDate() +1);
    } 
    return nextweek;
  }
  getcurrentweek(){
    var  nextweek=[];
    var mycurrentdate=new Date();
    var currentmonth=mycurrentdate.getMonth() + 1;
    var currentyear=mycurrentdate.getFullYear();
    var currentday=mycurrentdate.getDate();
    var currentdate=currentyear+'-'+currentmonth+'-'+currentday;

    var $val = new Date(currentdate);
      // Starting Monday not Sunday
      $val.setDate(($val.getDate() - $val.getDay() +1));
    for (var i = 0; i < 7; i++) {
      var myweekdate=new Date($val);
      var myweekmonth=myweekdate.getMonth() + 1;
      var myweekyear=myweekdate.getFullYear();
      var myweekday=myweekdate.getDate();
      var myweek=myweekyear+'-'+myweekmonth+'-'+myweekday;
      nextweek.push(myweek); 
      $val.setDate($val.getDate() +1);
    } 
    return nextweek;
  }
  getcurrentday(){
    var mycurrentdate=new Date();
    var currentmonth=mycurrentdate.getMonth() + 1;
    var currentyear=mycurrentdate.getFullYear();
    var currentday=mycurrentdate.getDate();
    var currentdate=currentyear+'-'+currentmonth+'-'+currentday;
    return currentdate;
  }

  getmonthwithoutweek(){
    var mycurrentdate=new Date();
    var currentyear=mycurrentdate.getFullYear();
    var currentmonth=mycurrentdate.getMonth();
    var date = new Date(currentyear, currentmonth, 1);
    var days = [];
    var myweek=this.getcurrentweek();
    while (date.getMonth() === currentmonth) {
      var mydate=new Date(date);
      var mymonth=mydate.getMonth()+1;
      var currentdate=mydate.getFullYear()+'-'+mymonth+'-'+mydate.getDate();
      var existe=this.verifyExist(currentdate,myweek);
      if(existe=='non'){
        days.push(currentdate);
      }
      date.setDate(date.getDate() + 1);
    }
    return days;

  }
  getcurrentmonth(){
    var mycurrentdate=new Date();
    var currentyear=mycurrentdate.getFullYear();
    var currentmonth=mycurrentdate.getMonth();
    var date = new Date(currentyear, currentmonth, 1);
    var days = [];
    while (date.getMonth() === currentmonth) {
      var mydate=new Date(date);
      var mymonth=mydate.getMonth()+1;
      var currentdate=mydate.getFullYear()+'-'+mymonth+'-'+mydate.getDate();
      days.push(currentdate);
      date.setDate(date.getDate() + 1);
    }
    return days;

  }
  verifyExist($date,$week) {
    var existe='non';
    for(let val of $week){
      if(val==$date){
        existe='oui';
      }
    }
    return existe;

  }
  getYear(){
    return [2022, 2023, 2024, 2025, 2026, 2027, 2028];
  }
  getyearpaie(){
    return [{ 'year': 2022, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2023, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2024, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2025, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2026, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2027, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }, { 'year': 2028, 'total': 0, 'versement': 0, 'resteversement': 0, 'resteannuel': 0 }];
  }
  getyearsuivi(){
    return  [{ 'year': 2022, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2023, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2024, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2025, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2026, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2027, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }, { 'year': 2028, 'total': 0, 'versement': 0, 'resteversement': 0, 'value': [] }];
  }
  getDevise(){
    return ['FCFA','DALASI','FRANC GUINEE','EURO','DOLLAR'];
  }


}


