import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
declare var require: any;

// var Calendar = require('tui-calendar'); 
/* CommonJS */
// require("tui-calendar/dist/tui-cal.css");

// // If you use the default popups, use this.
// require("tui-date-picker/dist/tui-date-picker.css");
// require("tui-time-picker/dist/tui-time-picker.css");
// // import Calendar from 'tui-calendar'; /* ES6 */
// import "tui-calendar/dist/tui-cal.css";

// // If you use the default popups, use this.
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';


declare var $;
@Injectable({
  providedIn: 'root'
})

export class JsserviceService {
  default;
  replace;
  remove;
  error;
  
  constructor(public translate:TranslateService) { }

  getTranslation(key: string): Observable<any> {
    return this.translate.get(key);
  }
    popover(){
      // $(function () {
        // $('[data-toggle="popover"]').popover()
        $('[data-toggle=popover]').popover();
      // });
    }

    dropify(){
      this.translate.get('GlisserDeposerCliquer').subscribe(translation => this.default = translation);
      this.translate.get('GlisserDeposerCliquerRemplacer').subscribe(translation => this.replace = translation);
      this.translate.get('REMOVE').subscribe(translation => this.remove = translation);
      this.translate.get('ErrorChargement').subscribe(translation => this.error = translation);
      
      $('.dropify').dropify({
        messages: {
            'default': this.default,
            'replace': this.replace,
            'remove':  this.remove,
            'error':   this.error
        }
      });
      //  $('.dropify-clear').dropify();
    }
    dropifyparams($name1,$name2,$class){
      this.translate.get($name1).subscribe(translation => this.default = translation);
      this.translate.get($name2).subscribe(translation => this.replace = translation);
      this.translate.get('REMOVE').subscribe(translation => this.remove = translation);
      this.translate.get('ErrorChargement').subscribe(translation => this.error = translation);
      
      $($class).dropify({
        messages: {
            'default': this.default,
            'replace': this.replace,
            'remove':  this.remove,
            'error':   this.error
        }
      });

    }
   datepicker(){
    $('#showdate').datepicker({
      inline: false,
            language: 'en',
            startDate: new Date(),
            firstDay: '',
            weekends: [6, 0],
            dateFormat: '',
            altField: '',
            altFieldDateFormat: '@',
            toggleSelected: true,
            keyboardNav: true,

            position: 'bottom left',
            offset: 12,

            view: 'days',
            minView: 'days',

            showOtherMonths: true,
            selectOtherMonths: true,
            moveToOtherMonthsOnSelect: true,

            showOtherYears: true,
            selectOtherYears: true,
            moveToOtherYearsOnSelect: true,

            minDate: '',
            maxDate: '',
            disableNavWhenOutOfRange: true,

            multipleDates: false, // Boolean or Number
            multipleDatesSeparator: ',',
            range: false,

            todayButton: false,
            clearButton: false,

            showEvent: 'focus',
            autoClose: false,

            // navigation
            monthsField: 'monthsShort',
            prevHtml: '<svg><path d="M 17,12 l -5,5 l 5,5"></path></svg>',
            nextHtml: '<svg><path d="M 14,12 l 5,5 l -5,5"></path></svg>',
            navTitles: {
                days: 'MM, <i>yyyy</i>',
                months: 'yyyy',
                years: 'yyyy1 - yyyy2'
          }
        })
   }
   editor(){
     var placeholder;
     this.translate.get('SAISISSEZ').subscribe((data)=>{
        placeholder=data;
     })
    //Default Summernote Editor		
    $('#summernote').summernote({
          placeholder: placeholder,
          tabsize: 2,
          height: 150 ,
          addclass: {
            debug: false,
            classTags: [{title:"Button","value":"btn btn-success"},"jumbotron", "lead","img-rounded","img-circle", "img-responsive","btn", "btn btn-success","btn btn-danger","text-muted", "text-primary", "text-warning", "text-danger", "text-success", "table-bordered", "table-responsive", "alert", "alert alert-success", "alert alert-info", "alert alert-warning", "alert alert-danger", "visible-sm", "hidden-xs", "hidden-md", "hidden-lg", "hidden-print"]
        },
          toolbar: [
            // [groupName, [list of button]]
            ['para_style', ['style']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            // ['font', ['strikethrough', 'superscript', 'subscript']],
            ['fontsize', ['fontname', 'fontsize', 'height']],
            // ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph', 'hr']],
            ['insert', ['link']], 
            ['misc', ['fullscreen']]

  ]
        });
    
    //AirMode Summernote Editor	
      $(function() {
        $('#summernoteAir').summernote({
          height: 150,
          airMode: true
        });
      });
    

    
    // //Full Summernote Editor
    $('#summernotetoolbar').summernote({
        addclass: {
            debug: false,
            classTags: [{title:"Button","value":"btn btn-success"},"jumbotron", "lead","img-rounded","img-circle", "img-responsive","btn", "btn btn-success","btn btn-danger","text-muted", "text-primary", "text-warning", "text-danger", "text-success", "table-bordered", "table-responsive", "alert", "alert alert-success", "alert alert-info", "alert alert-warning", "alert alert-danger", "visible-sm", "hidden-xs", "hidden-md", "hidden-lg", "hidden-print"]
        },
        height: 150,
        toolbar: [
                  // [groupName, [list of button]]
                  ['para_style', ['style']],
                  ['style', ['bold', 'italic', 'underline', 'clear']],
                  ['font', ['strikethrough', 'superscript', 'subscript']],
                  ['fontsize', ['fontname', 'fontsize', 'height']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph', 'hr']],
                  ['table', ['table']],
                  ['insert', ['link', 'picture', 'video']],
                  ['do', ['undo', 'redo']],
                  ['misc', ['fullscreen', 'codeview', 'help']]
    
        ]
    });         


   }
  editorparams($id){
    var placeholder;
    this.translate.get('SAISISSEZ').subscribe((data)=>{
       placeholder=data;
    })
   //Default Summernote Editor
   $($id).summernote({  
    placeholder: placeholder,
    tabsize: 2,
    height: 150 ,
    addclass: {
      debug: false,
      classTags: [{title:"Button","value":"btn btn-success"},"jumbotron", "lead","img-rounded","img-circle", "img-responsive","btn", "btn btn-success","btn btn-danger","text-muted", "text-primary", "text-warning", "text-danger", "text-success", "table-bordered", "table-responsive", "alert", "alert alert-success", "alert alert-info", "alert alert-warning", "alert alert-danger", "visible-sm", "hidden-xs", "hidden-md", "hidden-lg", "hidden-print"]
  },
    toolbar: [
      // [groupName, [list of button]]
      ['para_style', ['style']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      // ['font', ['strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontname', 'fontsize', 'height']],
      // ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph', 'hr']],
      ['insert', ['link']], 
      ['misc', ['fullscreen']]

]
  });
  }
  resetparams($id){
    $($id).summernote('reset');
  }
   reset(){
    $('#summernote').summernote('reset');
    
  }

  calendar(){ 
  
    var MONTHLY_CUSTOM_THEME = {
      // month header 'dayname'
      'month.dayname.height': '42px',
      'month.dayname.borderLeft': 'none',
      'month.dayname.paddingLeft': '8px',
      'month.dayname.paddingRight': '0',
      'month.dayname.fontSize': '13px',
      'month.dayname.backgroundColor': 'inherit',
      'month.dayname.fontWeight': 'normal',
      'month.dayname.textAlign': 'left',
  
      // month day grid cell 'day'
      'month.holidayExceptThisMonth.color': '#f3acac',
      'month.dayExceptThisMonth.color': '#bbb',
      'month.weekend.backgroundColor': '#fafafa',
      'month.day.fontSize': '16px',
  
      // month schedule style
      'month.schedule.borderRadius': '5px',
      'month.schedule.height': '18px',
      'month.schedule.marginTop': '2px',
      'month.schedule.marginLeft': '10px',
      'month.schedule.marginRight': '10px',
  
      // month more view
      'month.moreView.boxShadow': 'none',
      'month.moreView.paddingBottom': '0',
      'month.moreView.border': '1px solid #9a935a',
      'month.moreView.backgroundColor': '#f9f3c6',
      'month.moreViewTitle.height': '28px',
      'month.moreViewTitle.marginBottom': '0',
      'month.moreViewTitle.backgroundColor': '#f4f4f4',
      'month.moreViewTitle.borderBottom': '1px solid #ddd',
      'month.moreViewTitle.padding': '0 10px',
      'month.moreViewList.padding': '10px'
    };
  
    // var cal = new Calendar('#calendar', {
    //   defaultView: 'month',
    //   theme: MONTHLY_CUSTOM_THEME 
    // }); 
  
    
  }


  
  

}
